<template>
  <div class="auth-to-website">
    <div class="auth-to-website__container">
      <div class="auth-to-website__container__title">
        {{ $t('components.ui.pages.auth-to-website.title') }}
      </div>

      <div class="auth-to-website__container__nav">
        <BlueButton
          :text="$t('components.ui.pages.auth-to-website.button')"
          @click="$pop.up('presentations')"
        />

        <a
          :href="_getWebsiteLink"
          target="_blank"
          class="auth-to-website__container__nav__link"
        >
          {{ $t('components.ui.pages.auth-to-website.text') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    BlueButton
  },
  computed: {
    _getWebsiteLink () {
      return `https://demolix.org/${this.$i18n.locale === 'ru' ? '/ru' : ''}` || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-to-website {
  position: relative;
  width: calc(100% + 20px);
  margin-bottom: 50px;
  padding: 15px 40px;
  background: linear-gradient(94.38deg, rgba(14, 14, 27, .3) 0%, rgba(47, 87, 233, .3) 100%), #0E0E1B;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);

  @media screen and (max-width: 1080px) {
    position: static;
    left: 0;
    width: 100%;
    max-width: 430px;
    padding: 30px 20px;
    border-radius: 10px;
  }

  .auth-to-website__container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1080px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
    }

    .auth-to-website__container__title {
      font-size: 26px;
      line-height: 100%;
      color: #FFFFFF;
      font-family: SemiBold;
      white-space: nowrap;

      @media screen and (max-width: 1080px) {
        max-width: 240px;
        white-space: normal;
        font-size: 24px;
      }
    }

    .auth-to-website__container__nav {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 30px;

      @media screen and (max-width: 1080px) {
        flex-direction: column;
      }

      button {
        width: 100%;
        max-width: 270px;
        font-size: 12px;

        @media screen and (max-width: 1080px) {
          max-width: 100%;
        }
      }

      .auth-to-website__container__nav__link {
        font-size: 12px;
        line-height: 100%;
        text-transform: uppercase;
        color: #FFFFFF;
        font-family: SemiBold;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }

        @media screen and (max-width: 1080px) {
          white-space: normal;
        }
      }
    }
  }
}
</style>
