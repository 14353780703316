<template>
  <div class="ci__ui">
    <div
      class="ci__ui__info-text top"
      v-for="(item, index) in topInfo"
      :key="index"
    >
      {{ item }}
    </div>

    <div
      class="input-container"
      :class="{
        error: errors.length > 0,
        'focus-info': topInfo.length > 0 && errors.length === 0
      }"
      @click="$refs.input.focus()"
    >
      <input
        type="text"
        ref="input"
        :value="modelValue"
        :readonly="!edit"
        :disabled="!edit"
        :name="name"
        :id="name"
        @input="$emit('update:modelValue', !edit ? modelValue : $event.target.value)"
      >

      <p
        class="input-container__placeholder"
        :class="{
          active: modelValue && modelValue.length > 0
        }"
      >
        {{ placeholder }}
      </p>

      <div
        v-if="errors.length > 0"
        class="error-icon"
      >
        <img src="@/static/svg/error-input-icon.svg">
      </div>
    </div>

    <div
      v-for="(error, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      <template
        v-if="error.type && error.type === 'text'"
      >
        {{ error.text }}
      </template>

      <template
        v-else-if="error.type && error.type === 'html-link'"
      >
        <RouterLink
          :to="error.link"
          v-html="error.text"
        />
      </template>

      <template
        v-else
      >
        {{ $t(error) }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    edit: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null
    },
    topInfo: {
      type: Array,
      default () {
        return []
      }
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      value: ''
    }
  }
}
</script>
